var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo mr10",
          attrs: {
            action: _vm.apiBaseURL + "admin/merchant/upload/safeFile",
            name: "multipart",
            data: _vm.uploadData,
            headers: _vm.myHeaders,
            "show-file-list": false,
            multiple: _vm.multiple,
            accept: _vm.accept,
            "before-upload": _vm.handleBeforeUpload,
            "on-progress": _vm.handleProgress,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "on-remove": _vm.handleRemove,
          },
        },
        [
          _vm.url && !_vm.disablePreview
            ? _c("div", { staticClass: "upLoadPicBox" }, [
                _c("div", { staticClass: "upLoad column" }, [
                  _c("i", {
                    staticClass: "el-icon-document-checked cameraIconfont",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "file-type" }, [
                    _vm._v(_vm._s(_vm.fileType)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.disablePreview || !_vm.url) &&
          _vm.checkPermi(["platform:upload:file"])
            ? _vm._t("upload-element", [
                _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                  _vm._v("点击上传"),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.loadingCount > 0
        ? _c("div", { staticClass: "progress-list" }, [
            _c(
              "div",
              { staticClass: "main" },
              _vm._l(_vm.loadingFiles, function (uploadingFile) {
                return _c("div", { staticClass: "progress-item" }, [
                  uploadingFile.percent <= 99
                    ? _c("div", { staticClass: "progress-bar" }, [
                        _vm._v(_vm._s(uploadingFile.percent) + "%"),
                      ])
                    : _c("div", { staticClass: "progress-bar" }, [
                        _vm._v("服务器处理中"),
                      ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progress-text" }, [
                    _vm._v(_vm._s(uploadingFile.name)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }