import { render, staticRenderFns } from "./setings.vue?vue&type=template&id=b4d002a2&scoped=true"
import script from "./setings.vue?vue&type=script&lang=js"
export * from "./setings.vue?vue&type=script&lang=js"
import style0 from "./setings.vue?vue&type=style&index=0&id=b4d002a2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4d002a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/lc-mer-aishuren/lc-mer-aishuren-mer-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4d002a2')) {
      api.createRecord('b4d002a2', component.options)
    } else {
      api.reload('b4d002a2', component.options)
    }
    module.hot.accept("./setings.vue?vue&type=template&id=b4d002a2&scoped=true", function () {
      api.rerender('b4d002a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/navBars/breadcrumb/setings.vue"
export default component.exports