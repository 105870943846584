var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo mr10 mb15",
          attrs: {
            action: "",
            "http-request": _vm.handleUploadForm,
            headers: _vm.myHeaders,
            "show-file-list": true,
            multiple: "",
          },
        },
        [
          _vm.url && !_vm.disablePreview
            ? _c("div", { staticClass: "upLoadPicBox" }, [
                _c("div", { staticClass: "upLoad column" }, [
                  _c("i", {
                    staticClass: "el-icon-document-checked cameraIconfont",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "file-type" }, [
                    _vm._v(_vm._s(_vm.fileType)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.disablePreview || !_vm.url) &&
          _vm.checkPermi(["platform:upload:file"])
            ? _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                _vm._v("点击上传"),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }